import { call, takeLatest, put, select, delay } from "redux-saga/effects";
import axios from "axios";
import endpoints from "../../../constants/endpoints";
import { sessionTokenSelector } from "../../login/redux/selectors";
import * as environmentSelectors from "./selectors";
import {
  FETCH_ENVIRONMENT_DETAIL,
  FETCH_ENVIRONMENT_DETAIL_SUCCESS,
  FETCH_ENVIRONMENT_DETAIL_FAILURE,
  //STARTSTOP_ENVIRONMENT,
  DELETE_ENVIRONMENT,
  DELETE_ENVIRONMENT_SUCCESS,
  DELETE_ENVIRONMENT_FAILURE,
  FETCH_ENV_VARIABLES,
  FETCH_ENV_VARIABLES_SUCCESS,
  UPDATE_ENV_VARIABLES,
  UPDATE_ENV_VARIABLES_SUCCESS,
  UPDATE_ENV_VARIABLES_FAILURE,
  FETCH_RESOURCES_SUCCESS,
  CREATE_ENVIRONMENT,
  FETCH_RESOURCES,
  FETCH_ENV_LOGS,
  FETCH_ENV_LOGS_SUCCESS,
  FETCH_ENV_LOGS_STATUS,
  FETCH_ENV_LOGS_STATUS_SUCCESS,
  UPDATE_ENVIRONMENT,
  UPDATE_ENVIRONMENT_SUCCESS,
  UPDATE_ENVIRONMENT_FAILURE,
  FETCH_PLUGIN_VERSION_CONFIG,
  FETCH_PLUGIN_VERSION_CONFIG_SUCCESS,
  FETCH_ENVIRONMENT_INSIGHT,
  FETCH_ENVIRONMENT_INSIGHT_SUCCESS,
  FETCH_ENVIRONMENT_OVERVIEW_INSIGHT,
  FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_SUCCESS,
  FETCH_ENV_STATE_SUCCESS,
  FETCH_ENV_STATE_INITIATE,
  FETCH_ENV_STATE,
  FETCH_ENV_ROLE_SUCCESS,
  FETCH_ENV_ROLE,
  FETCH_ACTIVITY_LOGS,
  FETCH_ACTIVITY_LOGS_SUCCESS,
  //UPDATE_ACTIVITY_LOGS,
  RECREATE_ENV,
  RECREATE_ENV_SUCCESS,
  RECREATE_ENV_FAILURE,
  FETCH_ADDONS,
  FETCH_ADDONS_SUCCESS,
  FETCH_ADDONS_FAILURE,
  FETCH_ADDON_INFO,
  FETCH_ADDON_INFO_SUCCESS,
  FETCH_ADDON_INFO_FAILURE,
  INSTALL_ADDON,
  INSTALL_ADDON_FAILURE,
  INSTALL_ADDON_SUCCESS,
  MODIFY_ADDON,
  MODIFY_ADDON_FAILURE,
  MODIFY_ADDON_SUCCESS,
  FETCH_INSTALLED_ADDONS,
  FETCH_INSTALLED_ADDONS_SUCCESS,
  FETCH_INSTALLED_ADDONS_FAILURE,
  UNINSTALL_ADDON,
  UNINSTALL_ADDON_FAILURE,
  UNINSTALL_ADDON_SUCCESS,
  CREATE_ENVIRONMENT_SUCCESS,
  CREATE_ENVIRONMENT_FAILURE,
  FETCH_ADDONS_STATUS_INITIATE,
  FETCH_ADDONS_STATUS,
  FETCH_ADDONS_STATUS_SUCCESS,
  FETCH_ADDONS_STATUS_FAILURE,
  FETCH_HPA_INSIGHT_SUCCESS,
  FETCH_HPA_INSIGHT_FAILURE,
  FETCH_HPA_INSIGHT,
  FETCH_ENVIRONMENT_INSIGHT_FAILURE,
  FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_FAILURE,
  FETCH_ENV_LOGS_FAILURE,
  FETCH_ENV_LOGS_STATUS_FAILURE,
  FETCH_ENV_VARIABLES_FAILURE,
  FETCH_ENVIRONMENT_PODS,
  FETCH_ENVIRONMENT_PODS_SUCCESS,
  FETCH_ENVIRONMENT_PODS_FAILURE,
  FETCH_RESOURCES_FAILURE,
  START_ENV,
  START_ENV_SUCCESS,
  START_ENV_FAILURE,
  STOP_ENV,
  STOP_ENV_FAILURE,
  STOP_ENV_SUCCESS,
  FETCH_ENV_WORKFLOW,
  FETCH_ENV_WORKFLOW_FAILURE,
  FETCH_ENV_WORKFLOW_SUCCESS,
  ROLLBACK_ENV, 
  ROLLBACK_ENV_FAILURE,
  ROLLBACK_ENV_SUCCESS,
  GET_STORAGE,
  GET_STORAGE_FAILURE,
  GET_STORAGE_SUCCESS,
  CREATE_STORAGE,
  CREATE_STORAGE_FAILURE,
  DELETE_STORAGE,
  DELETE_STORAGE_FAILURE,
  DELETE_STORAGE_SUCCESS,
  EDIT_STORAGE,
  EDIT_STORAGE_FAILURE,
  EDIT_STORAGE_SUCCESS,
  FETCH_ADDON_CATEGORIES,
  FETCH_ADDON_CATEGORIES_SUCCESS,
  FETCH_ADDON_CATEGORIES_FAILURE,
  INITIATE_FETCH_PACKAGE_STATUS,
  INITIATE_FETCH_PACKAGE_STATUS_SUCCESS,
  INITIATE_FETCH_PACKAGE_STATUS_FAILURE,
  UPDATE_CUSTOM_DOMAIN,
  UPDATE_CUSTOM_DOMAIN_SUCCESS,
  UPDATE_CUSTOM_DOMAIN_FAILURE,
  FETCH_ENV_SERVICE_DETAILS,
  UPDATE_ENV_SCHEDULE,
  UPDATE_ENV_SCHEDULE_SUCCESS,
  UPDATE_ENV_SCHEDULE_FAILURE,
  UPDATE_FILE_MANAGER_STATUS,
  UPDATE_FILE_MANAGER_STATUS_SUCCESS,
  UPDATE_FILE_MANAGER_STATUS_FAILURE,
  UPDATE_ENV_SCHEDULE_LOG,
  UPDATE_ENV_SCHEDULE_LOG_SUCCESS,
  UPDATE_ENV_SCHEDULE_LOG_FAILURE,
  //RECHECK_DOMAIN
  UPDATE_GIT_BRANCH,
  UPDATE_GIT_BRANCH_SUCCESS,
  UPDATE_GIT_BRANCH_FAILURE,
  CLONE_ENVIRONMENT,
  CLONE_ENVIRONMENT_SUCCESS,
  CLONE_ENVIRONMENT_FAILURE,
  UPDATE_EXTERNAL_SECRET,
  UPDATE_SECRET_SYNC,
  UPDATE_EXTERNAL_SECRET_SUCCESS,
  UPDATE_EXTERNAL_SECRET_FAILURE,
  FETCH_SECRET_LOGS,
  FETCH_SECRET_LOGS_SUCCESS,
  UPDATE_EXTERNAL_LOGGER_SUCCESS,
  UPDATE_EXTERNAL_LOGGER_FAILURE,
  UPDATE_EXTERNAL_LOGGER,
  UPDATE_ENV_NAME,
  FETCH_SYSTEM_VARIABLE_CONFIG,
  FETCH_SYSTEM_VARIABLE_CONFIG_SUCCESS,
  FETCH_LOGGER_CONFIG,
  FETCH_LOGGER_CONFIG_SUCCESS,
  FETCH_SCANNER_PLUGINS,
  FETCH_SCANNER_PLUGINS_SUCCESS,
  START_SCAN,
  FETCH_SPECIFIC_REPORT,
  FETCH_SPECIFIC_REPORT_SUCCESS,
  FETCH_SCAN_LIST,
  FETCH_SCAN_LIST_SUCCESS
} from "./actions";
import toast from "../../../components/toast/Toast";
import { GET_SIDEBAR_PROJECT_DETAILS } from '../../project/redux/actions'
import { UPDATE_CICD_SETTINGS, CLEAR_ENV_CI, RERUN_ENV_CI_WORKFLOW } from '../cicdredux/actions'
import paths from "../../../constants/paths";
import { CreateApp_TemplateTypes } from "../../../constants/enums";
import { FETCH_HELM_ENVIRONMENT_DETAIL } from "../../helmenvironment/redux/actions";

function envDetailsCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.ENV_DETAILS.replace(":eId", payload.data.id),
    config
  );
}

// Generator Call
function* fetchEnvDetails(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(envDetailsCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ENVIRONMENT_DETAIL_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ENVIRONMENT_DETAIL_FAILURE });
    }
  } catch (error) {
    if (
      error &&
      error.response &&
      (error.response.status === 404 || error.response.status === 401)
    ) {
      yield put({ type: FETCH_ENVIRONMENT_DETAIL_FAILURE });
    }
  }
}

// function startStopEnvCall(sessionToken, payload) {
//   const apiUrl = payload.data.isActive
//     ? endpoints.START_ENVIRONMENT
//     : endpoints.STOP_ENVIRONMENT;
//   const config = {
//     headers: {
//       Authorization: "basic " + sessionToken,
//     },
//   };
//   return axios.get(apiUrl.replace(":eId", payload.data.id), config);
// }

// Generator Call
// function* startStopEnvironment() {
//   try {
//     //  const sessionToken = yield select(sessionTokenSelector)
//     //  const response = yield call(startStopEnvCall, sessionToken , payload)
//     //  const data = response.data;
//     //  const msg = payload.data.isActive ? "Environment started" : "Environment stopped";
//     //  toast.success(msg);
//     //  if (data != null) {
//     //    yield put({ type: STARTSTOP_ENVIRONMENT_SUCCESS, data })
//     //  } else {
//     //  }
//   } catch (error) {}
// }

function deleteEnvCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.delete(
    endpoints.DELETE_ENVIRONMENT.replace(":eId", payload.data.id),
    config
  );
}

// Generator Call
function* deleteEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(deleteEnvCall, sessionToken, payload);
    if (response.status === 200) {
      yield put({ type: DELETE_ENVIRONMENT_SUCCESS });
      yield put({ type: CLEAR_ENV_CI });
      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS });
      toast.success("Environment deleted successfully");
      payload.data.history.push(
        payload.data.app_id > 0 ? "/app/" + payload.data.app_id : "/projects"
      );
    } else {
      toast.success("Operation Failed.  ");
      yield put({ type: DELETE_ENVIRONMENT_FAILURE });
      // payload.data.history.push((payload.data.app_id > 0 ?  "/app/" +  payload.data.app_id : "/projects" ))
    }
  } catch (error) {
    yield put({ type: DELETE_ENVIRONMENT_FAILURE });
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
    else toast.error("Operation failed. Please try again");
  }
}

function getEnvVariablesCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.ENVIRONMENT.GET_VARIABLES.replace(":eId", payload.data.id),
    config
  );
}

// Generator Call
function* fetchEnvVariables(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(getEnvVariablesCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ENV_VARIABLES_SUCCESS, data });
      //yield delay(5000);
      //yield call(startFetchState, payload);
    } else {
      yield put({ type: FETCH_ENV_VARIABLES_FAILURE });
      
    }
  } catch (error) {
    yield put({ type: FETCH_ENV_VARIABLES_FAILURE });
  }
}

function updateEnvVariablesCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(
    endpoints.ENVIRONMENT.UPDATE_VARIABLES.replace(":eId", payload.data.id),
    payload.data.jsonBody,
    config
  );
}

function* updateEnvVariables(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(updateEnvVariablesCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      // yield put({ type: FETCH_ENV_VARIABLES_SUCCESS, data })
      let message = "Environment variables saved, will effect in next build."
      if(payload.data?.jsonBody?.apply_immediately){
        message = "Environment variables are being updated."
      }
      toast.success(message);
      yield put({ type: UPDATE_ENV_VARIABLES_SUCCESS });
      yield call(fetchEnvVariables, payload);
      yield call(fetchEnvDetails, payload);
    } else {
      yield put({ type: UPDATE_ENV_VARIABLES_FAILURE });
    }
  } catch (error) {
    yield put({ type: UPDATE_ENV_VARIABLES_FAILURE });
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
    else toast.error("Environment variables update failed");
  }
}

function createEnvironmentApiCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(
    endpoints.CREATE_ENVIRONMENT,
    payload.data.jsonBody,
    config
  );
}

function* createEnvironment(payload) {
  // yield put({ type: BEGIN_LOADING_INDICATOR })
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      createEnvironmentApiCall,
      sessionToken,
      payload
    );
    const data = response.data;
    if (data !== null) {
      if(payload.data.triggerPayload) {
        yield put({ type: UPDATE_CICD_SETTINGS, data: { id: data.id, jsonBody: payload.data.triggerPayload, externalSource: true } })
      }
      yield put({ type: CREATE_ENVIRONMENT_SUCCESS});
      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS });
      toast.success("Environment creation process started");
        // payload.data.history.push(
        //   "/environment/" +
        //     data.id +
        //     (payload.data.jsonBody.git_branch && !payload.data.jsonBody.prevent_default_build ? "#cicd" : "#overview")
        // );
      payload.data.history.push({
        pathname: `${paths.ENVIRONMENTINFO.replace(":eId", data.id.toString())}${(payload.data.jsonBody.git_branch && !payload.data.jsonBody.prevent_default_build) ? "#cicd" : "#overview"}`,
        state: { initial: true }
      })
    } else {
      toast.error(data.error?? "Environment creation failed. Please try again");

      yield put({ type: CREATE_ENVIRONMENT_FAILURE });
    }
  } catch (error) {
    yield put({ type: CREATE_ENVIRONMENT_FAILURE });
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
    else toast.error("Environment creation failed. Please try again");
  }
}

function getResourcesCall(sessionToken) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.RESOURCES.FETCH_RESOURCES, config);
}

// Generator Call
function* fetchResources() {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(getResourcesCall, sessionToken);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_RESOURCES_SUCCESS, data });
    } else {
      yield put({ type: FETCH_RESOURCES_FAILURE });
    }
  } catch (error) {
    yield put({ type: FETCH_RESOURCES_FAILURE });
    
  }
}

function fetchLogsCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  const noOfLines = payload.data.duration ? payload.data.duration : 50;
  return axios.get(
    endpoints.ENVIRONMENT.START_FETCHING_LOGS.replace(
      ":eId",
      payload.data.id
    ).replace(":lines", noOfLines),
    config
  );
}

// Generator Call
function* startFetchLogs(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchLogsCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ENV_LOGS_SUCCESS, data });
      yield delay(3000);
      yield call(fetchLogStatus, payload);
    } else {
      yield put({ type: FETCH_ENV_LOGS_FAILURE });

    }
  } catch (error) {
    yield put({ type: FETCH_ENV_LOGS_FAILURE });

  }
}

function getLogStatusCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.ENVIRONMENT.GET_LOGS.replace(":eId", payload.data.id),
    config
  );
}

// Generator Call
function* fetchLogStatus(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(getLogStatusCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ENV_LOGS_STATUS_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ENV_LOGS_STATUS_FAILURE });
      
    }
  } catch (error) {
    yield put({ type: FETCH_ENV_LOGS_STATUS_FAILURE });
  }
}

function updateEnvironmentCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.put(
    endpoints.ENVIRONMENT.UPDATE_ENV.replace(":eId", payload.data.id),
    payload.data.jsonBody,
    config
  );
}

function* updateEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(updateEnvironmentCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      //yield put({ type: FETCH_ENV_VARIABLES_SUCCESS, data })
      yield put({ type: UPDATE_ENVIRONMENT_SUCCESS });
      toast.success( data.message ?? "Environment updated successfully");

      if(payload.data.jsonBody.operator_payload){
        if (payload.data.history) {
          payload.data.history.push({
            pathname: paths.ENVIRONMENTINFO.replace(":eId", payload.data.id),
          })
        }
      } else {
      yield call(fetchEnvDetails, payload);
      if (payload.data.source === 2 && payload.data.changeHash) {
        payload.data.changeHash("overview");
      }
      if (payload.data.source === 3 && payload.data.changeHash) {
        yield put({ type: RERUN_ENV_CI_WORKFLOW, data: {
          id: payload.data?.id,
          workflowName: ""
        } });
        payload.data.changeHash("cicd");
      }
    }
    } else {
      yield put({ type: UPDATE_ENVIRONMENT_FAILURE });
      toast.error(data.error ?? "Environment update failed. Please try again");
    }
  } catch (error) {
    yield put({ type: UPDATE_ENVIRONMENT_FAILURE });
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
    else toast.error("Environment update failed. Please try again");
  }
}

function getPluginVersionConfigCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.PLUGIN.GET_VERSION_CONFIG.replace(":id", payload.data.id),
    config
  );
}

// Generator Call
function* fetchPluginVersionConfig(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      getPluginVersionConfigCall,
      sessionToken,
      payload
    );
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_PLUGIN_VERSION_CONFIG_SUCCESS, data });
    } else {
      toast.error("Failed fetching plugin version config");
    }
  } catch (error) {
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
  }
}

function environmentInsightsCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(
    endpoints.ENVIRONMENT.GET_INSIGHTS.replace(":eId", payload.data.id),
    payload.data.payload,
    config
  );
}

// Generator Call
function* fetchEnvironmentInsights(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(environmentInsightsCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ENVIRONMENT_INSIGHT_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ENVIRONMENT_INSIGHT_FAILURE });
    }
  } catch (error) {
    yield put({ type: FETCH_ENVIRONMENT_INSIGHT_FAILURE });
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
    else toast.error("Unable to get insight. Please try again");
  }
}

function hpaInsightCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(
    endpoints.ENVIRONMENT.GET_HPA_INSIGHT.replace(":eId", payload.data.id),
    payload.data.payload,
    config
  );
}

// Generator Call
function* fetchHpaInsight(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(hpaInsightCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_HPA_INSIGHT_SUCCESS, data });
    } else {
      yield put({ type: FETCH_HPA_INSIGHT_FAILURE });
    }
  } catch (error) {
    yield put({ type: FETCH_HPA_INSIGHT_FAILURE });
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
    else toast.error("Unable to get insight. Please try again");
  }
}

function environmentOverViewInsightsCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(
    endpoints.ENVIRONMENT.GET_OVERVIEW_INSIGHTS.replace(
      ":eId",
      payload.data.id
    ),
    payload.data.payload,
    config
  );
}

// Generator Call
function* fetchEnvironmentOverViewInsights(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      environmentOverViewInsightsCall,
      sessionToken,
      payload
    );
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_FAILURE });
    }
  } catch (error) {
    yield put({ type: FETCH_ENVIRONMENT_OVERVIEW_INSIGHT_FAILURE });
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
    else toast.error("Unable to get insight. Please try again");
  }
}

function startFetchStateCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  let e = endpoints.ENVIRONMENT.GET_STATE_INITIATE.replace(":eId", payload.data.id)
  if(payload.data?.initial){
    e += "?action=Creating"
  }
  return axios.get(
    e,
    config
  );
}

// Generator Call
function* startFetchState(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(startFetchStateCall, sessionToken, payload);
    //const data = response.data;
    if (response.status === 200 || response.status === 201) {
      yield delay(500);
      yield call(fetchState, payload);
      // yield delay(1000)
      // yield call(fetchAddonsStatus, payload);

    } // else {
    // }
  } catch (error) {
    console.log(error)
  }
}
// Generator Call
function* initiateAddonStatus(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    yield call(startFetchStateCall, sessionToken, payload);
  } catch (error) {
    console.log(error)
  }
}

function fetchStateCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.ENVIRONMENT.GET_STATE.replace(":eId", payload.data.id),
    config
  );
}

// Generator Call
function* fetchState(payload) {
  try {
    const currentEnvId = yield select(environmentSelectors.currentEnvironmentSelector);
    if(parseInt(currentEnvId) !== parseInt(payload.data.id)){
      return
    }
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchStateCall, sessionToken, payload);
    const data = response.data;
    yield put({ type: FETCH_ENV_STATE_SUCCESS, data: data ? data : {} });
    // const certificate = data.Certificates && data.Certificates[0];
    // const certificateCondition = certificate?.status?.conditions &&
      // certificate.status.conditions[0];
    // let certificateStatus = certificateCondition?.status === "False";
    // if (payload.checkCertificate) {
    //   certificateStatus = false;
    // }
    // if (
    //   (!data ||
    //     !data.State ||
    //     data.State !== "Running" ||
    //     data.CName === "" ||
    //     certificateStatus) &&
    //   response.status === 200
    // ) {
    //   yield delay(3000);
    //   yield call(startFetchState, payload);
    // }
  } catch (err) {
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to fetch State");
    }
    // yield delay(3000);
    // yield call(fetchState, payload);
  }
}

// Generator Call
function* startGeneralFetchCall(payload, isNotDefault ) {
  try {
    const currentEnvId = yield select(environmentSelectors.currentEnvironmentSelector);
    if(parseInt(currentEnvId) !== parseInt(payload.data.id)){
      return
    }
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(startFetchStateCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      if(!isNotDefault)
      {
        yield delay(500);
        yield call(generalFetchState, payload);
      }
    } else {
      console.log(data)
    }
  } catch (error) {
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
  }
}

function* generalFetchState(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchStateCall, sessionToken, payload);
    const data = response.data;
    yield put({ type: FETCH_ENV_STATE_SUCCESS, data: data ? data : {} });
    if (!data || !data.State) {
      console.log(data)
      // yield call(startGeneralFetchCall, payload);
    }
  } catch (error) {
    console.log(error)
    //  yield put({ type: FETCH_ENV_STATE_FAILURE, data : data ? data : {} })
  }
}

function fetchEnvRoleCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.GET_ROLE.replace(":id", id).replace(":module", "environment"),
    config
  );
}

function* fetchEnvRole(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      fetchEnvRoleCall,
      sessionToken,
      payload.data.id
    );
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ENV_ROLE_SUCCESS, data });
    } else {
      console.log(data)
    }
  } catch (error) {
    if (
      error?.response?.data?.error
    )
      toast.error(error.response.data.error);
    else toast.error("Unable to get App Role. Please try again");
  }
}

function fetchActivityLogsCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.ENVIRONMENT.GET_ACTIVITY_LOGS.replace(":eId", id),
    config
  );
}

function* fetchActivityLogs(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      fetchActivityLogsCall,
      sessionToken,
      payload.data.id
    );
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ACTIVITY_LOGS_SUCCESS, data });
    } else {
      toast.error("Couldn't fetch Activity Logs");
    }
  } catch (err) {
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to fetch Activity Logs");
    }
  }
}

function fetchAddonsCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.PLUGIN.GET_ADDONS.replace(":id", payload.data.id).replace(":cat_id", payload.data.category ?? "").replace(":query", payload.data.searchText ?? ""), config);
}

function* fetchAddons(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchAddonsCall, sessionToken, payload);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ADDONS_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ADDONS_FAILURE, error: "Couldn't fetch addons" });
      toast.error("Couldn't fetch Addons");
    }
  } catch (err) {
    yield put({ type: FETCH_ADDONS_FAILURE, error: err.message });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to Fetch Addons");
    }
  }
}

function* fetchAddonCategories(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { Authorization: "basic " + sessionToken } };
    const response = yield axios.get(endpoints.PLUGIN.GET_ADDON_CATEGORIES.replace(":id", payload.data.id), config);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ADDON_CATEGORIES_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ADDON_CATEGORIES_FAILURE });
      //toast.error("Couldn't fetch Addons");
    }
  } catch (err) {
    yield put({ type: FETCH_ADDON_CATEGORIES_FAILURE });
    if (err?.response?.data?.error) {
      toast.error(err.response.data.error);
    }
    // else {
    //   toast.error("Failed to Fetch Addons");
    // }
  }
}

function fetchAddonInfoCall(sessionToken, data) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.ENVIRONMENT.GET_ADDON_INFO.replace(":eId", data.eId).replace(":aId", data.aId), config);
}

function* fetchAddonInfo(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchAddonInfoCall, sessionToken, payload.data);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ADDON_INFO_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ADDON_INFO_FAILURE, error: "Couldn't fetch addon Info" });
      // toast.error("Couldn't fetch Addon Info");
    }
  } catch (err) {
    yield put({ type: FETCH_ADDON_INFO_FAILURE, error: err.message });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to Fetch Addon Info");
    }
  }
}

function fetchInstalledAddonsCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.ENVIRONMENT.GET_INSTALLED_ADDONS.replace(":eId", id), config);
}

function* fetchInstalledAddons(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchInstalledAddonsCall, sessionToken, payload.data.id);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_INSTALLED_ADDONS_SUCCESS, data });
    } else {
      yield put({ type: FETCH_INSTALLED_ADDONS_FAILURE, error: "Couldn't fetch installed addons" });
      toast.error("Couldn't fetch installed Addons");
    }
  } catch (err) {
    yield put({ type: FETCH_INSTALLED_ADDONS_FAILURE, error: err.message });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to Fetch Installed Addons");
    }
  }
}

function fetchAddonsStatusCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.ENVIRONMENT.GET_ADDONS_STATUS.replace(":eId", id),
    config
  );
}
/*
function* startFetchAddonState(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    // const response = yield call(
      // fetchAddonsStatusCall,
    //   sessionToken,
    //   payload.data.id
    // );
    // const data = response.data;
    const response = yield call(startFetchStateCall, sessionToken, payload);
    const data = response.data
    if (data !== null) {
      yield delay(3000);
      // yield call(fetchState, payload);
      yield call(fetchAddonsStatus, payload);
    }
    // if (response.status === 200) {
      // yield delay(3000);
    // } else {
    // }
  } catch (error) {}
}*/

function* fetchAddonsStatus(payload) {
  try {

    // const currentEnvId = yield select(environmentSelectors.currentEnvironmentSelector);
    // if(currentEnvId !== payload?.data?.id){
    //   return 
    // }
    const sessionToken = yield select(sessionTokenSelector);
    // yield call(fetchState, payload);
    // const overAllResponse = yield call(fetchStateCall, sessionToken, payload);
    const response = yield call(
      fetchAddonsStatusCall,
      sessionToken,
      payload.data.id
    );
    // const installedResponse = yield call(fetchInstalledAddonsCall, sessionToken, payload.data.id);
    const data = response.data;
    // const installedAddons = installedResponse.data?.addons
    if (response.status === 200) {
      yield put({ type: FETCH_ADDONS_STATUS_SUCCESS, data: data });
      // yield delay(3000);
      // if (installedAddons) {
      //   const installedAddonsName = installedAddons.map(a => {
      //     return a.version?.info[0]?.name
      //   })
      //   let addonStatusNamesUnique= []
      //   if(data){
      //     const addonStatusNames = data.map(a => {
      //       return a.Condition[0]?.Name})
      //     addonStatusNamesUnique = [... new Set(addonStatusNames)]
      //   }
      //   if (installedAddonsName.length !== addonStatusNamesUnique.length) {
      //     // yield call(startGeneralFetchCall, payload);
      //     yield call(startFetchAddonState, payload);
      //   } else if (data !== null) {
      //     let reCall = false;
      //     data.forEach((d) => {
      //       if(d.Status === "Pending"){
      //         reCall = true
      //         return 
      //       }
      //       if (d && d.Condition && d.Condition[0]) {
      //         if (d.Condition[0].Ready === false) {
      //           reCall = true;
      //           return;
      //         }
      //       }
      //     });
      //     if (reCall) {
      //       // yield call(startGeneralFetchCall, payload);
      //       yield call(startFetchAddonState, payload);
      //     }
      //   }
      // }
    } else {
      yield put({ type: FETCH_ADDONS_STATUS_FAILURE });
      toast.error("Couldn't fetch addons status");
    }
  } catch (err) {
    // yield delay(3000);
    //  yield call(fetchAddonsStatus, payload);
    yield put({ type: FETCH_ADDONS_STATUS_FAILURE });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to fetch Addons Status");
    }
  }
}

function uninstallAddonCall(sessionToken, data) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.delete(
    endpoints.ENVIRONMENT.UNINSTALL_ADDON.replace(":eId", data.eId).replace(
      ":aId",
      data.aId
    ),
    config
  );
}

function* uninstallAddon(payload){
  try{
    const sessionToken = yield select(sessionTokenSelector)
   yield call(uninstallAddonCall, sessionToken , payload.data)
     yield call(fetchEnvDetails,{ data: {id: payload.data.eId}});
     yield call(fetchInstalledAddons,{ data: {id: payload.data.eId}});
     yield put({ type: UNINSTALL_ADDON_SUCCESS })
     toast.success("Addon Uninstall Initiated"); 
    //  yield call(startFetchAddonState,{ data: {id: payload.data.eId}});
    //  yield put({ type: FETCH_ENV_STATE_SUCCESS, data : {"State" : "Pending"}})
  } catch (err) {
    yield put({ type: UNINSTALL_ADDON_FAILURE, error: err.message });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Uninstalling addon Failed. Try again");
    }
  }
}

function installAddonCall(sessionToken, data) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(
    endpoints.ENVIRONMENT.INSTALL_ADDON.replace(":eId", data.eId),
    data.payload,
    config
  );
}

function* installAddon(payload){
  try{
    const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(installAddonCall, sessionToken , payload.data)
   const data = response.data;
   const message =  response.data?.message ?? data.error ?? "Addon Installation Initiated"
   if (response.status === 200) {
     yield put({ type: INSTALL_ADDON_SUCCESS, data })
     toast.success(message);
    //  yield call(fetchEnvDetails,{ data: {id: payload.data.eId}});
     yield call(fetchInstalledAddons,{ data: {id: payload.data.eId}});
     yield call(startFetchStateCall, sessionToken, {data: {id: payload.data.eId}});
    //  yield call(startFetchAddonState,{ data: {id: payload.data.eId}});
    //  yield put({ type: FETCH_ENV_STATE_SUCCESS, data : {"State" : "Pending"}})
    //  payload.data.changeHash("overview")
    //  yield delay(3000);

    //  if(payload.data.history)
    // payload.data.history.push(`${paths.ENVIRONMENTINFO.replace(":eId", payload.data.eId)}#activity`)
    // if(payload.data.changeHash){
    // }
     
    } else {
      yield put({
        type: INSTALL_ADDON_FAILURE,
        error: "Couldn't install addons",
      });
      toast.error(data.message ?? data.error ?? "Couldn't install Addon");
    }
  } catch (err) {
    yield put({ type: INSTALL_ADDON_FAILURE, error: err.message });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Couldn't install Addon. Please Try Again");
    }
      
  }
}

function modifyAddonCall(sessionToken, data) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.put(
    endpoints.ENVIRONMENT.MODIFY_ADDON.replace(":eId", data.eId).replace(":aId", data.aId),
    data.payload,
    config,
  );
}

function* modifyAddon(payload){
  try{
    const sessionToken = yield select(sessionTokenSelector)
   const response = yield call(modifyAddonCall, sessionToken , payload.data)
   const data = response.data;
   const message =  response.data?.message ?? data.error ?? "Addon Modification Initiated"
   if (response.status === 200) {
     yield put({ type: MODIFY_ADDON_SUCCESS, data })
     yield put({ type: FETCH_ADDON_INFO, data: payload.data })
     yield call(startFetchStateCall, sessionToken, {data: {id: payload.data.eId}});
     toast.success(message);
    //  yield call(fetchEnvDetails,{ data: {id: payload.data.eId}});
    //  yield call(fetchInstalledAddons,{ data: {id: payload.data.eId}});
    //  yield call(startFetchAddonState,{ data: {id: payload.data.eId}});
    //  yield put({ type: FETCH_ENV_STATE_SUCCESS, data : {"State" : "Pending"}})
    //  payload.data.changeHash("overview")
    //  yield delay(3000);

    //  if(payload.data.history)
    // payload.data.history.push(`${paths.ENVIRONMENTINFO.replace(":eId", payload.data.eId)}#activity`)
    // if(payload.data.changeHash){
    // }
     
    } else {
      yield put({
        type: MODIFY_ADDON_FAILURE,
        error: "Couldn't modify addons",
      });
      toast.error(data.message ?? data.error ?? "Couldn't modify Addon");
    }
  } catch (err) {
    yield put({ type: MODIFY_ADDON_FAILURE, error: err.message });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Couldn't modify Addon. Please Try Again");
    }
      
  }
}

// function* updateActivityLogs(payload){
//   try{
//     const data = payload.data.logs
//     //  yield put({ type: UPDATE_ACTIVITY_LOG_SUCCESS, data })
//   }catch(err){
//     toast.error(=);
//   }
// }

function reCreateEnvironmentCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
      "Content-Type": "application/json",
    },
  };
  return axios.post(
    endpoints.ENVIRONMENT.RECREATE_ENV.replace(":eId", payload.data.id),
    payload.data.payload,
    config
  );
}

function* reCreateEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(reCreateEnvironmentCall, sessionToken, payload);
    const data = response.data;
    if(data !== null) {
      yield put({ type: RECREATE_ENV_SUCCESS });
      yield call(fetchEnvDetails, {
        data: {
          id: payload.data.id,
        },
      });
      if(payload.data.changeHash){
        payload.data.changeHash("cicd")
      }
      toast.success(data.message ?? data.error ?? "Environment recreation triggered");
    }else{
      yield put({ type: RECREATE_ENV_FAILURE });
      toast.error(data.error?? data.message ?? "Recreation Environment Failed. Try Again");
    }
  } catch (err) {
    yield put({ type: RECREATE_ENV_FAILURE });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Recreation Environment Failed. Try Again");
    }
  }
}

function fetchEnvPodsCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.ENVIRONMENT.GET_PODS.replace(":eId", id), config);
}

function* fetchEnvPods(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    yield call(startGeneralFetchCall, payload, true);
    yield delay(3000)
    const response = yield call(fetchEnvPodsCall, sessionToken, payload.data.id);
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_ENVIRONMENT_PODS_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ENVIRONMENT_PODS_FAILURE, error: "Failed fetching Pods. Try Again" });
      toast.error(data.message ?? data.error ?? "Failed fetching Pods. Try Again");
    }
  } catch (err) {
    yield put({ type: FETCH_ENVIRONMENT_PODS_FAILURE, error: err.message });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed fetching Pods. Try Again");
    }
  }
}

function startEnvironmentCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(endpoints.ENVIRONMENT.START.replace(":eId", id), {}, config);
}

function* startEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(startEnvironmentCall, sessionToken, payload.data.id);
    const data = response.data;
    if (response.status === 200) {
      toast.success(data.message ?? "Environement Start Triggered")
      // yield delay(3000)
      yield call(startFetchState, payload);
      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS })
      yield put({ type: START_ENV_SUCCESS });
    } else {
      yield put({ type: START_ENV_FAILURE });
      toast.error( data.error ?? "Failed to trigger Start Environment");
    }
  } catch (err) {
    yield put({ type: START_ENV_FAILURE });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to trigger Start Environment");
    }
  }
}

function rollbackEnvCall(sessionToken, data) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(endpoints.ENVIRONMENT.ROLLBACK.replace(":eId",data.id), data.workflow, config);
}

function* rollbackEnv(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(rollbackEnvCall, sessionToken, payload.data);
    const data = response.data;
    if (response.status === 200) {
      toast.success(data.message ?? "Environement RollBack Triggered")
      // yield delay(3000)
      if(payload.data.changeHash){
        payload.data.changeHash("overview")
      }
      yield put({ type: ROLLBACK_ENV_SUCCESS });
    } else {
      yield put({ type: ROLLBACK_ENV_FAILURE });
      toast.error( data.error ?? "Failed to trigger Environment Rollback");
    }
  } catch (err) {
    yield put({ type: ROLLBACK_ENV_FAILURE });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to trigger Environment Rollback");
    }
  }
}

function fetchEnvWorkflowCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.ENVIRONMENT.FETCH_BUILD_IMAGE.replace(":eId", id), config);
}

function* fetchEnvWorkflows(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(fetchEnvWorkflowCall, sessionToken, payload.data.id);
    const data = response.data;
    if (response.status === 200) {
      // yield delay(3000)
      yield put({ type: FETCH_ENV_WORKFLOW_SUCCESS, data });
    } else {
      yield put({ type: FETCH_ENV_WORKFLOW_FAILURE });
      toast.error( data.error ?? "Failed to fetch Environment workflow");
    }
  } catch (err) {
    yield put({ type: FETCH_ENV_WORKFLOW_FAILURE });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      // toast.error("Failed to fetch Environment workflow");
    }
  }
}

function stopEnvironmentCall(sessionToken, id) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.post(endpoints.ENVIRONMENT.STOP.replace(":eId", id), {}, config);
}

function* stopEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(stopEnvironmentCall, sessionToken, payload.data.id);
    const data = response.data;
    if (response.status === 200) {
      toast.success(data.message ?? "Environement Stop Triggered")
      if(payload.data.changeHash){
        payload.data.changeHash("overview")
      }
      // yield delay(3000)
      yield call(startFetchState, payload);
      yield put({ type: GET_SIDEBAR_PROJECT_DETAILS })
      yield put({ type: STOP_ENV_SUCCESS });
    } else {
      yield put({ type: STOP_ENV_FAILURE });
      toast.error( data.error ?? "Failed to trigger Start Environment");
    }
  } catch (err) {
    yield put({ type: STOP_ENV_FAILURE });
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to trigger Stop Environment");
    }
  }
}

function fetchStorageCall(sessionToken, payload){
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.ENVIRONMENT.FETCH_STORAGE.replace(":eId", payload.data.id), config)

}
function getStorageCall(sessionToken, payload){
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(endpoints.ENVIRONMENT.GET_STORAGE.replace(":eId", payload.data.id), config)

}

function* fetchStorage(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    yield fetchStorageCall(sessionToken, payload);
    yield delay(3000)
    const response = yield getStorageCall(sessionToken, payload);

    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      // toast.success(data.message ?? "Storage fetch")
      yield put({ type: GET_STORAGE_SUCCESS , data: data});
    } else{
      yield put({ type: GET_STORAGE_FAILURE }) 
    }
  } catch (error) {
    yield put({ type: GET_STORAGE_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
      toast.error(error.response.data.error);
    }
  }
}

function* createStorage(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.post(endpoints.ENVIRONMENT.CREATE_STORAGE.replace(":eId", payload.data.id), payload.data.payload, config);
    const data = response.data;
    if (response.status === 201) {
      yield put({ type: CREATE_STORAGE_FAILURE });
      yield put({ type: GET_STORAGE, data: {id: payload.data.id} });
      toast.success(data.message ?? "Storage created successfully");
    } else {
      yield put({ type: CREATE_STORAGE_FAILURE })
      yield put({ type: GET_STORAGE, data: {id: payload.data.id} });
      toast.success(data.message ?? "Storage creation failed");
    }
  } catch (error) {
      yield put({ type: GET_STORAGE, data: {id: payload.data.id} });
    yield put({ type: CREATE_STORAGE_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* editStorage(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.put(endpoints.ENVIRONMENT.EDIT_STORAGE.replace(":eId", payload.data.id).replace(":sId", payload.data.sId), payload.data.payload, config);

    const data = response.data;
    if (response.status === 200 || response.status === 201) {
      yield put({ type: EDIT_STORAGE_SUCCESS });
      yield put({ type: GET_STORAGE, data: {id: payload.data.id} });
      toast.success(data.message ?? "Storage updated successfully");
    } else {
      yield put({ type: EDIT_STORAGE_FAILURE })
      toast.error(data.message ?? "Storage update failed");
    }
  } catch (error) {
    yield put({ type: EDIT_STORAGE_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* deleteStorage(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.delete(endpoints.ENVIRONMENT.DELETE_STORAGE.replace(":eId", payload.data.id).replace(":sId", payload.data.sId), config);

    const data = response.data;
    if (response.status === 201 || response.status === 200) {
      yield put({ type: DELETE_STORAGE_SUCCESS });
      yield put({ type: GET_STORAGE, data: {id: payload.data.id} });
      toast.success(data.message ?? "Storage deletion successfully");
    } else {
      yield put({ type: DELETE_STORAGE_FAILURE })
      toast.success(data.message ?? "Storage delete failed");
    }
  } catch (error) {
    yield put({ type: DELETE_STORAGE_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* fetchPackageStatus(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector)
    const config = { headers : { 'Authorization': 'basic ' + sessionToken } }
    const response = yield axios.get(endpoints.ENVIRONMENT.FETCH_PACKAGE_STATUS.replace(":eId", payload.data.id), config);

    // const data = response.data;
    if (response.status === 201 || response.status === 200) {
      yield put({ type: INITIATE_FETCH_PACKAGE_STATUS_SUCCESS });
    } else {
      yield put({ type: INITIATE_FETCH_PACKAGE_STATUS_FAILURE })
    }
  } catch (error) {
    yield put({ type: INITIATE_FETCH_PACKAGE_STATUS_FAILURE }) 
    if(error && error.response && error.response.data && error.response.data.error){
     toast.error(error.response.data.error);
    }
  }
}

function* updateCustomDomain(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { "Content-Type": "application/json", 'Authorization': 'basic ' + sessionToken } }
    yield axios.post(endpoints.ENVIRONMENT.UPDATE_CUSTOM_DOMAIN.replace(":eId", payload.data.id), payload.data.jsonBody, config);
    
    //const data = response.data;
    // if (data !== null) {
      // yield put({ type: FETCH_ENV_VARIABLES_SUCCESS, data })
      toast.success("Custom domain saved, provisioning certificate in process");
      yield put({ type: UPDATE_CUSTOM_DOMAIN_SUCCESS });
      if (payload.data.callBack)
        payload.data.callBack();
      yield call(fetchEnvVariables, payload);
      yield call(fetchEnvDetails, payload);
    // } else {
      // yield put({ type: UPDATE_CUSTOM_DOMAIN_FAILURE });
      // toast.error(response.message);
    // }
  } catch (error) {
    yield put({ type: UPDATE_CUSTOM_DOMAIN_FAILURE });
    if (error?.response?.data?.error)
      toast.error(error.response.data.error);
    else
      toast.error("Custom domain update failed");
  }
}

function* fetchServiceDetails(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
    yield axios.get(endpoints.ENVIRONMENT.OPERATOR_SERVICE_DETAILS.replace(":eId", payload.data.id), config);
  } catch (error) {
    if (error?.response?.data?.error)
      toast.error(error.response.data.error);
  }
}

function* updateEnvSchedule(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { "Content-Type": "application/json", 'Authorization': 'basic ' + sessionToken } }
    const _url = payload.data.isHelm ? endpoints.HELMENVIRONMENT.UPDATE_ENV_SCHEDULE : endpoints.ENVIRONMENT.UPDATE_ENV_SCHEDULE;
    const response = yield axios.post(_url.replace(":eId", payload.data.id), payload.data.jsonBody, config);

    // const data = response.data;
    if (response.status === 200) {
      toast.success("Environment scheduler updated");
      yield put({ type: UPDATE_ENV_SCHEDULE_SUCCESS });
      if (payload.data.isHelm) {
        yield put({ type: FETCH_HELM_ENVIRONMENT_DETAIL, data: payload.data })
      }
      else {
        yield call(fetchEnvDetails, payload);
      }
    } else {
      yield put({ type: UPDATE_ENV_SCHEDULE_FAILURE });
    }
  } catch (error) {
    yield put({ type: UPDATE_ENV_SCHEDULE_FAILURE });
    if (error?.response?.data?.error)
      toast.error(error.response.data.error);
    else
      toast.error("Environment scheduler update failed");
  }
}

function* fetchScheduleLog(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = { headers: { "Content-Type": "application/json", 'Authorization': 'basic ' + sessionToken } }
    const _url = payload.data.isHelm ? endpoints.HELMENVIRONMENT.FETCH_SCHEDULE_LOGS : endpoints.ENVIRONMENT.FETCH_SCHEDULE_LOGS;
    const response = yield axios.get(_url.replace(":eId", payload.data.eId), config);

    const data = response.data;
    if (data !== null) {
      yield put({ type: UPDATE_ENV_SCHEDULE_LOG_SUCCESS, data });
    } else {
      yield put({ type: UPDATE_ENV_SCHEDULE_LOG_FAILURE });
    }
  } catch (error) {
    yield put({ type: UPDATE_ENV_SCHEDULE_LOG_FAILURE });
    if (error?.response?.data?.error)
      toast.error(error.response.data.error);
    // else
    //   toast.error("Environment schedule update failed");
  }
}

// function* recheckDomain(payload) {
//   try {
//     const sessionToken = yield select(sessionTokenSelector)
//     const config = { headers: { 'Authorization': 'basic ' + sessionToken } }
//     const response = yield axios.get(endpoints.ENVIRONMENT.RECHECK_DOMAIN.replace(":eId", payload.data.id), config);
//     payload.data.callback();
//     toast.success("Domain rechecking initiated");
//     // const data = response.data;
//     //if (response.status === 201 || response.status === 200) {
//       //yield put({ type: INITIATE_FETCH_PACKAGE_STATUS_SUCCESS });
//     //}
//     // else {
//     //   yield put({ type: INITIATE_FETCH_PACKAGE_STATUS_FAILURE })
//     // }
//   } catch (error) {
//     payload.data.callback();
//     //yield put({ type: INITIATE_FETCH_PACKAGE_STATUS_FAILURE })
//     if (error && error.response && error.response.data && error.response.data.error) {
//       toast.error(error.response.data.error);
//     }
//   }
// }

function* updateFileManagerStatus(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };
    const response = yield axios.post(
      endpoints.ENVIRONMENT.UPDATE_FILE_MANAGER_STATUS.replace(
        ":eId",
        payload.data.id
      ).replace(":status", payload.data.status),
      null,
      config
    );

    const data = response.data;
    if (data !== null) {
      toast.success(data.message ?? "File manager status updated successfully");
      yield put({ type: UPDATE_FILE_MANAGER_STATUS_SUCCESS });
      yield put({
        type: FETCH_ENVIRONMENT_DETAIL,
        data: { id: payload.data.id },
      });
    } else {
      yield put({ type: UPDATE_FILE_MANAGER_STATUS_FAILURE });
    }
  } catch (error) {
    yield put({ type: UPDATE_FILE_MANAGER_STATUS_FAILURE });
    if (error?.response?.data?.error) toast.error(error.response.data.error);
    else toast.error("Failed updating file manager status");
  }
}

function* updateGitBranch(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };

    let endpoint =
      payload.data.service_type === CreateApp_TemplateTypes.git
        ? endpoints.ENVIRONMENT.UPDATE_GIT_BRANCH
        : endpoints.ENVIRONMENT.UPDATE_CR_TAG;
        
    const response = yield axios.post(
      endpoint.replace(":eId", payload.data.eId),
      payload.data.jsonBody,
      config
    );

    const data = response.data;
    if (data !== null) {
      toast.success(
        payload.data.service_type === CreateApp_TemplateTypes.git
          ? "Branch updated successfully"
          : "Tag updated successfully"
      );
      yield put({ type: UPDATE_GIT_BRANCH_SUCCESS, data });
      yield call(fetchEnvDetails, {data: {id: payload.data.eId}})

      if(payload.data.callback){
        payload.data.callback();
      }
    } else {
      yield put({ type: UPDATE_GIT_BRANCH_FAILURE });
    }
  } catch (error) {
    yield put({ type: UPDATE_GIT_BRANCH_FAILURE });
    if (error?.response?.data?.error) toast.error(error.response.data.error);
  }
}

function* cloneEnvironment(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "basic " + sessionToken,
      },
    };

    const response = yield axios.post(endpoints.ENVIRONMENT.CLONE_ENVIRONMENT.replace(":eId", payload.data.eId),
      payload.data.jsonBody,
      config
    );

    const data = response.data;
    if (data !== null) {
      toast.success("Environment cloning process started");
      yield put({ type: CLONE_ENVIRONMENT_SUCCESS });
      if (payload.data.callBack)
        payload.data.callBack(data);
    } else {
      yield put({ type: CLONE_ENVIRONMENT_FAILURE });
    }
  } catch (error) {
    yield put({ type: CLONE_ENVIRONMENT_FAILURE });
    if (error?.response?.data?.error) toast.error(error.response.data.error);
  }
}

function updateExternalSecretCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.put(
    endpoints.ENVIRONMENT.UPDATE_SECRETS.replace(":eId", payload.data.eId),
    payload.data.external_secret,
    config
  );
}

function* updateExternalSecret(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    yield call(updateExternalSecretCall, sessionToken, payload);
    yield put({type: UPDATE_EXTERNAL_SECRET_SUCCESS});
    yield call(fetchEnvDetails, { data: {id: payload.data.eId}});
    toast.success("Secret Updated Successfully");
  } catch (error) {
    yield put({type:UPDATE_EXTERNAL_SECRET_FAILURE})
    toast.success("Error while updating secret");
  }
}

function updateSecretSyncCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.put(
    endpoints.ENVIRONMENT.UPDATE_SECRETS_SYNC.replace(":eId", payload.data.eId),
    null,
    config
  );
}

function* updateSecretSync(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    yield call(updateSecretSyncCall, sessionToken, payload);
    toast.success("Secret Sync Successfully");
  } catch (error) {
    toast.success("Error while fetching secret");
  }
}

function fetchSecretLogsCall(sessionToken, eId) {
  const config = {
    headers: {
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.get(
    endpoints.ENVIRONMENT.FETCH_SECRETS_LOGS.replace(":eId", eId),
    config
  );
}

function* fetchSecretLogs(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(
      fetchSecretLogsCall,
      sessionToken,
      payload.data.eId
    );
    const data = response.data;
    if (data !== null) {
      yield put({ type: FETCH_SECRET_LOGS_SUCCESS, data });
    } else {
      toast.error("Couldn't fetch Secret Logs");
    }
  } catch (err) {
    if(err?.response?.data?.error){
      toast.error(err.response.data.error);
    }
    else{
      toast.error("Failed to fetch Secret Logs");
    }
  }
}

function updateExternalLoggerCall(sessionToken, payload) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "basic " + sessionToken,
    },
  };
  return axios.put(
    endpoints.ENVIRONMENT.UPDATE_LOGGER.replace(":eId", payload.data.eId),
    payload.data.external_secret,
    config
  );
}

function* updateExternalLogger(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    yield call(updateExternalLoggerCall, sessionToken, payload);
    yield put({type: UPDATE_EXTERNAL_LOGGER_SUCCESS});
    yield call(fetchEnvDetails, { data: {id: payload.data.eId}});
    toast.success("Secret Updated Successfully");
  } catch (error) {
    yield put({type:UPDATE_EXTERNAL_LOGGER_FAILURE})
    toast.error(error.response?error.response.data.message:"Error while updating secret");
  }
}

function renameEnvCall(sessionToken, payload) {
  const config = {
    headers: {
      Authorization: 'basic ' + sessionToken,
    },
  };

  return axios.put(
    endpoints.UPDATE_ENV_NAME.replace(":id", payload.data.id),
    payload.data.name,
    config
  );
}

// Generator Call
function* updateEnvName(payload) {
  try {
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(renameEnvCall, sessionToken, payload);
    if(response.status){
      yield call(fetchEnvDetails, payload);
      toast.success('Environment name updated successfully')
    }
  } catch (error) {
      toast.error(error.response.data.error);
  }
}

function systemVariableApicall(sessionToken, payload) {
  const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken,
    }
  }
  return axios.get(endpoints.SYSTEM_VARIABLE.replace(":id", payload.data.pid), config)
} 

function* getSettingVariableConfig(payload) {
  try{
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(systemVariableApicall, sessionToken, payload);
    const data = response.data;
    if(data !== null) {
      yield put({type: FETCH_SYSTEM_VARIABLE_CONFIG_SUCCESS, data});
    }
  }catch(error){
    toast.error(error.response.data.error);
  }
}

function loggerConfigApicall(sessionToken) {
  const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken,
    }
  }
  return axios.get(endpoints.ENVIRONMENT.LOGGER_CONFIG, config)
} 

function* fetchLoggerConfig() {
  try{
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(loggerConfigApicall, sessionToken);
    const data = response.data;
    if(data !== null) {
      yield put({type: FETCH_LOGGER_CONFIG_SUCCESS, data});
    }
  }catch(error){
    toast.error(error.response.data.error);
  }
}

function scannerPluginApicall(sessionToken, payload) {
  const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken,
    }
  }
  return axios.get(endpoints.ENVIRONMENT.SCANNER_PLUGINS.replace(":eId", payload.data.eId), config)
} 

function* fetchScannerPlugins(payload) {
  try{
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(scannerPluginApicall, sessionToken, payload);
    const data = response.data;
    if(data !== null) {
      yield put({type: FETCH_SCANNER_PLUGINS_SUCCESS, data});
    }
  }catch(error){
    toast.error(error.response.data.error);
  }
}

function scannerListsApicall(sessionToken, payload) {
  const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken,
    }
  }
  return axios.get(endpoints.ENVIRONMENT.SCANNER_LISTS.replace(":eId", payload.data.eId), config)
} 

function* fetchScanList(payload) {
  try{
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(scannerListsApicall, sessionToken, payload);
    const data = response.data;
    if(data !== null) {
      yield put({type: FETCH_SCAN_LIST_SUCCESS, data});
    }
    if( payload.data.callBack) {
      payload.data.callBack();
    }

  }catch(error){
    // toast.error(error.response.data.error);
    console.log("error list")
  }
}

function startScannerApicall(sessionToken, payload) {
  const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken,
    }
  }
  return axios.post(endpoints.ENVIRONMENT.START_SCANNER.replace(":eId", payload.data.eId), 
  {name:payload.data.plugin},
  config)
} 

function* startScan(payload) {
  try{
    toast.success('Scan is triggered successfully')
    const sessionToken = yield select(sessionTokenSelector);
    yield call(startScannerApicall, sessionToken, payload);
    yield call(fetchScanList, payload);
  }catch(error){
    toast.error(error.response.data.error);
  }
}

function specificReportApicall(sessionToken, payload) {
  const config = {
    headers : {
      'Content-Type': 'application/json',
      'Authorization': 'basic ' + sessionToken,
    }
  }
  return axios.get(endpoints.ENVIRONMENT.SPECIFIC_RERPORT.replace(":eId", payload.data.eId).replace(":reportId", payload.data.listId), config)
} 

function* fetchSpecificReport(payload) {
  try{
    const sessionToken = yield select(sessionTokenSelector);
    const response = yield call(specificReportApicall, sessionToken, payload);
    const data = response.data;
    if(data !== null) {
      yield put({type: FETCH_SPECIFIC_REPORT_SUCCESS, data});
    }
  }catch(error){
    toast.error(error.response.data.error);
  }
}

// watcher saga: watches for actions dispatched to the store, starts worker saga
export default function* watcherSaga() {
  yield takeLatest(FETCH_ENVIRONMENT_DETAIL, fetchEnvDetails);
  // yield takeLatest(STARTSTOP_ENVIRONMENT, startStopEnvironment);
  yield takeLatest(DELETE_ENVIRONMENT, deleteEnvironment);
  yield takeLatest(FETCH_ENV_VARIABLES, fetchEnvVariables);
  yield takeLatest(UPDATE_ENV_VARIABLES, updateEnvVariables);
  yield takeLatest(CREATE_ENVIRONMENT, createEnvironment);
  yield takeLatest(FETCH_RESOURCES, fetchResources);
  yield takeLatest(FETCH_ENV_LOGS, startFetchLogs);
  yield takeLatest(FETCH_ENV_LOGS_STATUS, fetchLogStatus);
  yield takeLatest(UPDATE_ENVIRONMENT, updateEnvironment);
  yield takeLatest(FETCH_PLUGIN_VERSION_CONFIG, fetchPluginVersionConfig);
  yield takeLatest(FETCH_ENVIRONMENT_INSIGHT, fetchEnvironmentInsights);
  yield takeLatest(
    FETCH_ENVIRONMENT_OVERVIEW_INSIGHT,
    fetchEnvironmentOverViewInsights
  );
  yield takeLatest(FETCH_ENV_STATE_INITIATE, startFetchState);
  yield takeLatest(FETCH_ENV_STATE, fetchState);
  yield takeLatest(FETCH_ENV_ROLE, fetchEnvRole);
  yield takeLatest(FETCH_ACTIVITY_LOGS, fetchActivityLogs);
  //yield takeLatest(UPDATE_ACTIVITY_LOGS, updateActivityLogs);
  yield takeLatest(RECREATE_ENV, reCreateEnvironment);
  yield takeLatest(FETCH_ADDON_CATEGORIES, fetchAddonCategories);
  yield takeLatest(FETCH_ADDONS, fetchAddons);
  yield takeLatest(FETCH_ADDON_INFO, fetchAddonInfo);
  yield takeLatest(FETCH_INSTALLED_ADDONS, fetchInstalledAddons);
  yield takeLatest(FETCH_ADDONS_STATUS, fetchAddonsStatus);
  yield takeLatest(FETCH_ADDONS_STATUS_INITIATE, initiateAddonStatus);
  yield takeLatest(INSTALL_ADDON, installAddon);
  yield takeLatest(MODIFY_ADDON, modifyAddon);
  yield takeLatest(UNINSTALL_ADDON, uninstallAddon);
  yield takeLatest(FETCH_HPA_INSIGHT, fetchHpaInsight);
  yield takeLatest(FETCH_ENVIRONMENT_PODS, fetchEnvPods);
  yield takeLatest(FETCH_ENV_WORKFLOW, fetchEnvWorkflows);
  yield takeLatest(ROLLBACK_ENV, rollbackEnv);
  yield takeLatest(START_ENV, startEnvironment);
  yield takeLatest(STOP_ENV, stopEnvironment);
  yield takeLatest(GET_STORAGE, fetchStorage);
  yield takeLatest(CREATE_STORAGE, createStorage);
  yield takeLatest(EDIT_STORAGE, editStorage);
  yield takeLatest(DELETE_STORAGE, deleteStorage);
  yield takeLatest(INITIATE_FETCH_PACKAGE_STATUS, fetchPackageStatus);
  yield takeLatest(UPDATE_CUSTOM_DOMAIN, updateCustomDomain);
  yield takeLatest(FETCH_ENV_SERVICE_DETAILS, fetchServiceDetails);
  yield takeLatest(UPDATE_ENV_SCHEDULE, updateEnvSchedule);
  yield takeLatest(UPDATE_ENV_SCHEDULE_LOG, fetchScheduleLog);
  yield takeLatest(UPDATE_FILE_MANAGER_STATUS, updateFileManagerStatus);
  //yield takeLatest(RECHECK_DOMAIN, recheckDomain); 
  yield takeLatest(UPDATE_GIT_BRANCH, updateGitBranch);
  yield takeLatest(CLONE_ENVIRONMENT, cloneEnvironment);
  yield takeLatest(UPDATE_EXTERNAL_SECRET, updateExternalSecret);
  yield takeLatest(UPDATE_SECRET_SYNC, updateSecretSync);
  yield takeLatest(FETCH_SECRET_LOGS, fetchSecretLogs);
  yield takeLatest(UPDATE_EXTERNAL_LOGGER, updateExternalLogger);
  yield takeLatest(UPDATE_ENV_NAME, updateEnvName);
  yield takeLatest(FETCH_SYSTEM_VARIABLE_CONFIG, getSettingVariableConfig);
  yield takeLatest(FETCH_LOGGER_CONFIG, fetchLoggerConfig);
  yield takeLatest(FETCH_SCANNER_PLUGINS, fetchScannerPlugins);
  yield takeLatest(START_SCAN, startScan);
  yield takeLatest(FETCH_SCAN_LIST, fetchScanList);
  yield takeLatest(FETCH_SPECIFIC_REPORT, fetchSpecificReport);
}
