import { makeStyles, Tab, Tabs, createStyles } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
//import PropTypes from 'prop-types';
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import Subscription from "../../../subscription/Susbcription";
import OrganizationResource from "../../../resource/Resource";
import Plugin from "../../../plugin/Plugin";
import ClusterList from "../../../cluster/ClusterList";
import RegistryTab from "../registrytab/RegistryTab";
import DNSTab from "../dnstab/DnsTab";
import { redirectToClusterChanger } from "../../../cluster/redux/actions";
import { useTranslation } from "react-i18next";
import ChartRepoTab from "../chartrepostab/ChartRepoTab";
import ChartCatalogTab from "../chartcatalogtab/ChartCatalogTab";
import paths from "../../../../constants/paths";
import { TabPanel } from "../../../../components/tabpanel/TabPanel";
import { WithWidth } from '@material-ui/core/withWidth';
import { History } from "history";

/* istanbul ignore next */
function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

/* istanbul ignore next */
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "inherit",
      display: "flex",
      padding: "0",
      margin: "0",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.down("md")]: {
        backgroundColor: "white",
      },
    },
    tabPanel: {
      padding: "0",
      margin: "0",
      width: "100%",
      minWidth: 250,
    },
  })
);

interface Props extends PropsFromRedux, WithWidth {
  history: History;
  innerTab: number;
  updateInnerTab: (_value: number) => void;
}

export function AdminTab(props: Props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [t] = useTranslation();

  const getNamebyTabIndex = (newValue: number) => {
    const tabPanelObj = document.getElementById("settingsTab-" + newValue);
    if (tabPanelObj) {
      const name = tabPanelObj.getAttribute("name");
      if (name) {
        props.history.push({
          pathname:
            paths.ORGANIZATIONINFO.replace(":id", props.organizationInfo?.id) +
            "#admin_" +
            name,
        });
      }
    }
  };

  const handleChange = (_: any, newValue: number) => {
    //setValue(newValue);
    console.log({ newValue });
    getNamebyTabIndex(newValue);
    props.updateInnerTab(newValue);
  };

  useEffect(() => {
    setValue(props.innerTab);
  }, [props.innerTab]);

  useEffect(() => {
    if (props.newClusterData && props.newClusterData.id > 0) {
      // setValue(3);
      props.updateInnerTab(4);
    }
    return () => {
      props.updateInnerTab(0);
    };
  }, []);

  useEffect(() => {
    if (props.redirectToCluster) {
      //setValue(3);
      props.updateInnerTab(4);
      props.redirectToClusterChanger();
    }
  }, [props?.redirectToCluster]);

  return (
    <div className={classes.root} data-test="main-container">
      <Tabs
        orientation={isWidthDown("md", props.width) ? undefined : "vertical"}
        value={value}
        variant="scrollable"
        onChange={handleChange}
        className={classes.tabs}
        indicatorColor="primary"
      >
        <Tab
          label={t("Organization.AdminTab.subscription")}
          {...a11yProps(1)}
          data-test="subscription-tab"
        />
        <Tab
          label={t("Organization.AdminTab.resources")}
          {...a11yProps(2)}
          data-test="resource-tab"
        />
        <Tab
          label={t("Organization.AdminTab.registry")}
          {...a11yProps(3)}
          data-test="registry-tab"
        />
        <Tab
          label={t("Organization.AdminTab.dns")}
          {...a11yProps(4)}
          data-test="dns-tab"
        />
        <Tab
          label={t("Organization.AdminTab.clusters")}
          {...a11yProps(5)}
          data-test="cluster-tab"
        />
        <Tab
          label={t("Organization.AdminTab.plugins")}
          {...a11yProps(6)}
          data-test="plugin-tab"
        />
        <Tab
          label={t("Organization.AdminTab.chartRepos")}
          {...a11yProps(7)}
          data-test="repo-tab"
        />
        <Tab
          label={t("Organization.AdminTab.chartCatalog")}
          {...a11yProps(8)}
          data-test="catalog-tab"
        />
        {/* <Tab label={t('Organization.AdminTab.operators')} { ...a11yProps(9) } data-test="operator-tab" />  */}
      </Tabs>

      <TabPanel
        value={value}
        index={0}
        className={classes.tabPanel}
        name="subscription"
        padding={2}
        tabPanel="settingsTab"
      >
        <Subscription />
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        className={classes.tabPanel}
        name="resource"
        padding={2}
        tabPanel="settingsTab"
      >
        <OrganizationResource />
      </TabPanel>
      <TabPanel
        value={value}
        index={2}
        className={classes.tabPanel}
        name="registry"
        padding={2}
        tabPanel="settingsTab"
      >
        <RegistryTab history={props.history} />
      </TabPanel>
      <TabPanel
        value={value}
        index={3}
        className={classes.tabPanel}
        name="dns"
        padding={2}
        tabPanel="settingsTab"
      >
        <DNSTab />
      </TabPanel>
      <TabPanel
        value={value}
        index={4}
        className={classes.tabPanel}
        name="cluster"
        padding={2}
        tabPanel="settingsTab"
      >
        <ClusterList history={props.history} />
      </TabPanel>
      <TabPanel
        value={value}
        index={5}
        className={classes.tabPanel}
        name="plugin"
        padding={2}
        tabPanel="settingsTab"
      >
        <Plugin />
      </TabPanel>
      <TabPanel
        value={value}
        index={6}
        className={classes.tabPanel}
        name="chartRepos"
        padding={2}
        tabPanel="settingsTab"
      >
        <ChartRepoTab />
      </TabPanel>
      <TabPanel
        value={value}
        index={7}
        className={classes.tabPanel}
        name="chartCatalog"
        padding={2}
        tabPanel="settingsTab"
      >
        <ChartCatalogTab />
      </TabPanel>
    </div>
  );
}

/* istanbul ignore next */
const mapStateToProps = (state: any) => ({
  newClusterData: state.ClusterReducer.newClusterData,
  redirectToCluster: state.ClusterReducer.redirectToCluster,
  organizationInfo: state.OrganizationReducer.organizationInfo,
});

/* istanbul ignore next */
const mapDispatchtoProps = (dispatch: Dispatch) => {
  return {
    redirectToClusterChanger: () => dispatch(redirectToClusterChanger()),
  };
};

const connector = connect(mapStateToProps, mapDispatchtoProps);
export default connector(withWidth()(AdminTab));

type PropsFromRedux = ConnectedProps<typeof connector>;
