import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { getSecuritySeverity } from "../../../../helpers/utils";
import KeyValueRow from "../../../../components/keyvaluerow/KeyValueRow";

const useStyles = makeStyles({
    cardHeader: {
        padding: "0 !important",
      },
      expand: {
        transform: "rotate(0deg)",
      },
      expandOpen: {
        transform: "rotate(180deg)",
      },
      cardHeaderAction: {
        marginTop: 8,
        marginRight: 20,
      },
});

interface Props {
  detail: any;
  serialNum: number;
}

function VulnerableDetail(props: Props) {
  const classes = useStyles();

  const details = props.detail

  const [expandSysVar, setExpandSysVar] = useState(false)

  const handleExpand = () => {
    setExpandSysVar(!expandSysVar);
  };

  const vulnerabilityRow = (rowKey: string, rowValue: string) =>{
    return (
      <div style={{paddingBottom:"10px"}}>
          <KeyValueRow rowKey={rowKey} rowValue={rowValue}/>
      </div>
    )
  }

  return (
    <>
      <Card className="m-t-20">
      <CardHeader
        className={classes.cardHeader}
        classes={{ action: classes.cardHeaderAction }}
        title={
          <Typography variant="h5" display="inline">
            <strong><span>{props.serialNum}. </span> {details.title}</strong>
          </Typography>
        }
        avatar={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expandSysVar,
            })}
            onClick={() => handleExpand()}
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        action={
          <Tooltip title={"Severity is "+ props.detail.properties.severity} arrow>
          <Typography variant="h5" display="inline" 
          style={{ color: getSecuritySeverity(props.detail.properties.severity)}}>
            {details.properties?.severity}
          </Typography>
          </Tooltip>
        }
      />
      <Collapse in={expandSysVar}>
        <Divider />
        <br />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {vulnerabilityRow("Status",details.properties.status)}
              <div style={{paddingBottom:"10px"}}><KeyValueRow rowKey="Identifier" 
              rowValue={details.identifier} type="url" src={`https://www.google.com/search?q=${details.identifier}`}/></div>
              {vulnerabilityRow("Blueprint",details.blueprint)}
              {vulnerabilityRow("Relations",details.relations)}
              {vulnerabilityRow("Team",details.team)}
            </Grid>
            <Grid item xs={12} md={6}>
              {vulnerabilityRow("Fixed Version",details.properties.fixed_version)}
              {vulnerabilityRow("Installed Version",details.properties.installed_version)}
              {vulnerabilityRow("Package",details.properties.package)}
              {vulnerabilityRow("Recommendation",details.properties.recommendation)}
              {vulnerabilityRow("References",details.properties.references)}
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2} direction="row">
                <Grid item style={{color:"#2979ff"}}>Description</Grid>
                <Grid item>{details.properties.description}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
    </>
  );
}


export default VulnerableDetail;
