import { makeStyles, Tab, Tabs, WithWidth } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
//import PropTypes from 'prop-types';
import React from 'react';
import CreateOrganization from '../../createorganization/CreateOrganization';
import { useTranslation } from 'react-i18next';
import { TabPanel } from "../../../../components/tabpanel/TabPanel"

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'inherit',
    display: 'flex',
    padding: '0',
    margin: '0',
    [theme.breakpoints.down('md')]: {
      flexDirection: "column",
    }
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      backgroundColor: 'white'
    }
  },
  tabPanel: {
    padding: '0',
    margin: '0',
    width: '100%',
    minWidth: 250,
  }
}));

interface Props extends WithWidth {

}

export function SettingsTab(props: Props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [t] = useTranslation()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root} data-test="main-container">
      <Tabs
        orientation={isWidthDown('md', props.width) ? undefined : "vertical"}
        value={value}
        variant="scrollable"
        onChange={handleChange}
        className={classes.tabs}
        indicatorColor="primary"
      >

        <Tab label={t('Organization.OverViewTab.general')} {...a11yProps(1)} data-test="general-tab" />
        {/* <Tab label="Stats" { ...a11yProps(2) } />
            <Tab label="Settings" { ...a11yProps(3) } />  */}

      </Tabs>

      <TabPanel value={value} index={0} className={classes.tabPanel} name="createOrganization" padding={2} tabPanel="settingsTab">
        <CreateOrganization source={2} />
      </TabPanel>
      {/* <TabPanel value={ value } index={ 1 } className={ classes.tabPanel }>
            <OverviewCI handleChange={ handleChange }/>
          </TabPanel>
          <TabPanel value={ value } index={ 2 } className={ classes.tabPanel }>
            <SettingsTab/>
          </TabPanel> */}
    </div>
  );
}

export default withWidth()(SettingsTab);