export const AppConstants = {
    ClusterProvider: {
        GCP: 'gcp',
        EKS: 'aws',
        CLOUDFLARE: 'cloudflare',
        Other: 'other'
    },
    Status: {
        Running: 'Running',
        Pending: 'Pending',
        Stopped: 'Stopped',
        Stopping: 'Stopping',
        Starting: "Starting",
        Succeeded: "Succeeded",
        Failed: "Failed",
        Undefined: "Undefined"
    },
    PackageStatus: {
        Installed: "installed",
        NotInstalled: "not-installed",
    },
    AddonStatus: {
        Running: "Running",
        FetchingStatus: "Fetching Status",
        Uninstalling: "Uninstalling"
    },
    EnvironmentStatus: {
        Running: 'Running',
        Pending: 'Pending',
        Stopped: 'Stopped',
        Stopping: 'Stopping',
        UpdatingVariable: 'Updating variables',
        Starting: "Starting",
        Succeeded: "Succeeded",
        Failed: "Failed",
        FetchingStatus: "Fetching Status",
        NotReady: "Not Ready"
    },
    ClusterStatus: {
        Drafted: 'drafted',
        Planning: 'cluster-planing',
        Planned: 'planned',
        Applying: 'cluster-applying',
        Applied: 'applied',
        Destroyed: 'destroyed',
        Imported: 'imported',
        PackageInstalling: 'package-installing',
        PackageInstalled: 'package-installed',
        Failed: 'failed',
    },
    WorkflowStatus: {
        Succeeded: 'Succeeded',
        Running: 'Running',
        Pending: 'Pending',
        Failed: 'Failed',
        Error: "Error",
    },
    BackupStatus: {
        New: "New",
        InProgress: "InProgress",
        Completed: "Completed",
        Failed: "Failed",
        FailedValidation: "FailedValidation",
        PartiallyFailed: "PartiallyFailed",
        PartiallyDone: "PartiallyDone",
        Deleting: "Deleting"
    },
    DeploymentStatus: {
        Unknown: "unknown",
        Deployed: "deployed",
        Uninstalled: "uninstalled",
        Superseded: "superseded",
        Failed: "failed",
        Uninstalling: "uninstalling",
        Pending_Install: "pending-install",
        Pending_Upgrade: "pending-upgrade",
        Pending_Rollback: "pending-rollback"
    },
    OperatorStatus: {
        Installing: "Installing",
        Succeeded: "Succeeded",
        Failed: "Failed"
    },
    gitProviders: [{
        name: "Github",
        templateType: 1,
        service_name: "github",
        icon: "/images/icons/github.svg",
        authType: 1,
        authorizeEndpoint: window?.config?.REACT_APP_GITHUB_AUTHORIZE_ENDPOINT,
        params: [
            `client_id=${window?.config?.REACT_APP_GITHUB_CLIENTID}`,
            `redirect_uri=${window?.config?.REACT_APP_OAUTH_REDIRECT_URL}`,
            `scope=repo,admin:repo_hook`,
            `state=github_repo`
        ].join("&")
    },
    {
        name: "GitLab",
        templateType: 1,
        service_name: "gitlab",
        icon: "/images/icons/gitlab.svg",
        authType: 2,
        authorizeEndpoint: window?.config?.REACT_APP_GITLAB_AUTHORIZE_ENDPOINT,
        params: [
            `access_type=online`,
            `client_id=${window?.config?.REACT_APP_GITLAB_CLIENTID}`,
            `redirect_uri=${window?.config?.REACT_APP_GITLAB_OAUTH_REDIRECT_URL}`,
            `scope=api+sudo+profile+email+openid+read_user+write_repository+write_registry`,
            `response_type=code`,
            `state=gitlab_repo`
        ].join("&"),
        config: {
            "keys": [
                "service_url",
                "service_code"
                //"service_user"
            ],
            "properties": {
                "service_code": {
                    "description": "",
                    "placeholder": "XXXX-XXXX-XXXX",
                    "errorDescription": "",
                    "form": true,
                    "title": "Token",
                    "type": "string",
                    "validation": new RegExp('^[ A-Za-z0-9_@.#&+-]{20,30}$'),
                    "value": "",
                    "hidden": true
                },
                // "service_user": {
                //     "description": "",
                //     "placeholder": "username",
                //     "errorDescription": "",
                //     "form": true,
                //     "title": "User",
                //     "type": "string",
                //     "validation": "",
                //     "value": ""
                // },
                "service_url": {
                    "description": "",
                    "placeholder": "https://gitlab.com",
                    "errorDescription": "",
                    "form": true,
                    "title": "Base Url",
                    "type": "string",
                    "validation": "",
                    "value": "",
                }
            },
            "type": "object"
        },
        infoLinkUrl: "https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html",
        infoLinkText: "How to create a token in GitLab?",
    },
    {
        name: "BitBucket",
        templateType: 1,
        service_name: "bitbucket",
        icon: "/images/icons/bitbucket.svg",
        authType: 1, // ?client_id={client_id}&response_type=code
        authorizeEndpoint: window?.config?.REACT_APP_BITBUCKET_AUTHORIZE_ENDPOINT,
        params: [
            `access_type=online`,
            `client_id=${window?.config?.REACT_APP_BITBUCKET_CLIENTID}`,
            `response_type=code`,
            //`redirect_uri=${window?.config?.REACT_APP_OAUTH_REDIRECT_URL_BITBUCKET}`, //REACT_APP_OAUTH_REDIRECT_URL_BITBUCKET=http://localhost:3000/login/external/bitbucket
            //`response_type=repository`,
            `state=bitbucket_repo`
        ].join("&")
    }],
    oauthSignupProviders: [
        {
            name: "Github",
            service_name: "github",
            icon: "/images/icons/github.svg",
            authorizeEndpoint: window?.config?.REACT_APP_GITHUB_AUTHORIZE_ENDPOINT,
            params: [
                `client_id=${window?.config?.REACT_APP_GITHUB_CLIENTID}`,
                `redirect_uri=${window?.config?.REACT_APP_OAUTH_REDIRECT_URL}`,
                `scope=user:email,repo,admin:repo_hook`,
                `state=github`
            ].join("&")
        },
        // {
        //     name: "GitLab",
        //     service_name: "gitlab",
        //     icon: "/images/icons/gitlab.svg",
        //     authorizeEndpoint: window?.config?.REACT_APP_GITLAB_AUTHORIZE_ENDPOINT,
        //     params: [
        //         `access_type=online`,
        //         `client_id=${window?.config?.REACT_APP_GITLAB_CLIENTID}`,
        //         `redirect_uri=${window?.config?.REACT_APP_GITLAB_OAUTH_REDIRECT_URL}`,
        //         `response_type=code`,
        //         `state=gitlab`
        //     ].join("&"),
        // },
    ],
    crProviders: [{
        name: "DockerHub",
        templateType: 2,
        service_name: "dockerhub",
        icon: "/images/icons/dockerhub.svg",
        authType: 2,
        config: {
            "keys": [
                "service_code",
                "service_user"
            ],
            "properties": {
                "service_code": {
                    "description": "",
                    "errorDescription": "",
                    "form": true,
                    "title": "Token",
                    "type": "string",
                    "validation": new RegExp('^[A-Za-z0-9_@.#&+-]{20,40}$'),
                    "value": "",
                    "hidden": true
                },
                "service_user": {
                    "description": "",
                    "errorDescription": "",
                    "form": true,
                    "title": "User",
                    "type": "string",
                    "validation": "",
                    "value": ""
                }
            },
            "type": "object"
        },
        infoLinkUrl: "https://docs.docker.com/docker-hub/access-tokens/",
        infoLinkText: "How to create a token in DockerHub?",
    },
    {
        name: "Amazon ECR",
        templateType: 2,
        service_name: "aws_ecr",
        icon: "/images/icons/amazonEcr.svg",
        authType: 2,
        config: {
            "keys": [
                "access_id",
                "secret_key",
                "region"
            ],
            "properties": {
                "access_id": {
                    "description": "",
                    "errorDescription": "",
                    "form": true,
                    "title": "Access Key",
                    "type": "string",
                    "validation": "",
                    "value": "",
                    "hidden": true,
                },
                "secret_key": {
                    "description": "",
                    "errorDescription": "",
                    "form": true,
                    "title": "Secret Key",
                    "type": "string",
                    "validation": "",
                    "value": "",
                    "hidden": true
                },
                "region": {
                    "description": "",
                    "errorDescription": "",
                    "form": true,
                    "title": "Region",
                    "type": "list",
                    //"items": [],
                    "itemsUrl": "/registry/aws-region",
                    "validation": "",
                    "value": ""
                }
            },
            "type": "object"
        },
        infoLinkUrl: "https://docs.aws.amazon.com/powershell/latest/userguide/pstools-appendix-sign-up.html",
        infoLinkText: "How to create AWS secret key and access key?",
    }],
    repositoryAuthTypes: {
        PUBLIC: "Public",
        BASIC: "Basic",
        BEARER: "Bearer Token",
        CUSTOM: "Custom",
    },
    Weekdays: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    packages: {
        VELERO: "velero",
        SECRET_PATCHER: "zerone-secret-patcher",
        TEKTON:'tekton-pipelines'
    },
    validationRegex: {
        email: new RegExp(/([\w.-]+@([\w-]+)\.+\w{2,}$)/),
        default: new RegExp(/^[A-Za-z0-9_ -]{3,30}$/),
        streetaddress: new RegExp(/^[A-Za-z0-9 - , -]+$/),
        projectCode: new RegExp(/^(\w|\d){0,5}$/),
        domain: new RegExp(/^([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+$/),
        url: new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/),
    },
    DomainStatus: {
        Failed: "failed",
        Pending: "pending",
        Processing: "processing"
    },
    PodStatus: {
        Pending: "Pending",
        Running: "Running",
        Succeeded: "Succeeded",
        Failed: "Failed",
        Unknown: "Unknown"
    },
    externalSecret: [{
        name: "aws",
        displayName: "AWS",
        config: {
            "keys": ["aws_credential"],
            "type": "object",
            "properties": {
                "aws_credential": {
                    "keys": ["access_key", "secret_key", "region"],
                    "properties": {
                        "access_key": {
                            "description": "",
                            "form": true,
                            "hidden": true,
                            "title": "Access Key",
                            "type": "string",
                            "value": ""
                        },
                        "region": {
                            "description": "",
                            "form": true,
                            "title": "AWS region",
                            "type": "string",
                            "value": ""
                        },
                        "secret_key": {
                            "description": "",
                            "form": true,
                            "hidden": true,
                            "title": "Secret Key",
                            "type": "string",
                            "value": ""
                        }
                    },
                    "type": "object"
                }
            }
        }
    },
    {
        name: "vault",
        displayName: "HashiCorp",
        config: {
            "keys": ["vault_credential"],
            "type": "object",
            "properties": {
                "vault_credential": {
                    "keys": ["server", "path", "version", "token"],
                    "properties": {
                        "server": {
                            "description": "",
                            "form": true,
                            // "hidden": true,
                            "title": "server",
                            "type": "string",
                            "value": ""
                        },
                        "path": {
                            "description": "",
                            "form": true,
                            "title": "path",
                            "type": "string",
                            "value": ""
                        },
                        "version": {
                            "description": "",
                            "form": true,
                            // "hidden": true,
                            "title": "version",
                            "type": "string",
                            "value": ""
                        },
                        "token": {
                            "description": "",
                            "form": true,
                            "hidden": true,
                            "title": "token",
                            "type": "string",
                            "value": ""
                        }
                    },
                    "type": "object"
                }
            }
        }
    },
    {
        name: "gcp",
        displayName: "GCP",
        config: {
            "keys": ["gcp_credential"],
            "type": "object",
            "properties": {
                "gcp_credential": {
                    "keys": ["service_account", "project_id"],
                    "properties": {
                        "service_account": {
                            "description": "Select credential file",
                            "form": true,
                            "required": true,
                            "title": "Service account file",
                            "type": "file",
                            "value": ""
                        },
                        "project_id": {
                            "description": "",
                            "form": true,
                            "title": "Project ID",
                            "type": "string",
                            "value": ""
                        },
                    },
                    "type": "object"
                }
            }
        }
    },
    ],
    externalLoggerProviders: {
        s3: "s3",
        cloudwatch: "cloudwatch",
        elastic: "elastic",
        loki: "loki",
        kafka: "kafka",
        gcp: "gcp"
    },
    externalLogger: [{
        name: "s3",
        displayName: "S3",
        config: {
            "keys": ["aws_credential"],
            "type": "object",
            "properties": {
                "aws_credential": {
                    "keys": ["access_key", "secret_key", "bucket", "region"],
                    "properties": {
                        "access_key": {
                            "description": "",
                            "form": true,
                            "hidden": true,
                            "title": "Access Key",
                            "type": "string",
                            "value": ""
                        },
                        "secret_key": {
                            "description": "",
                            "form": true,
                            "hidden": true,
                            "title": "Secret Key",
                            "type": "string",
                            "value": ""
                        },
                        "bucket": {
                            "description": "",
                            "form": true,
                            // "hidden": true,
                            "title": "Bucket",
                            "type": "string",
                            "value": ""
                        },
                        "region": {
                            "description": "",
                            "form": true,
                            "title": "AWS region",
                            "type": "string",
                            "value": ""
                        },
                    },
                    "type": "object"
                }
            }
        }
    },
    {
        name: "cloudwatch",
        displayName: "Cloudwatch",
        config: {
            "keys": ["aws_credential"],
            "type": "object",
            "properties": {
                "aws_credential": {
                    "keys": ["access_key", "secret_key", "region"],
                    "properties": {
                        "access_key": {
                            "description": "",
                            "form": true,
                            "hidden": true,
                            "title": "Access Key",
                            "type": "string",
                            "value": ""
                        },
                        "secret_key": {
                            "description": "",
                            "form": true,
                            "hidden": true,
                            "title": "Secret Key",
                            "type": "string",
                            "value": ""
                        },
                        "region": {
                            "description": "",
                            "form": true,
                            "title": "AWS region",
                            "type": "string",
                            "value": ""
                        },
                    },
                    "type": "object"
                }
            }
        }
    },
    {
        name: "elastic",
        displayName: "Elastic",
        config: {
            "keys": ["elastic_credential"],
            "type": "object",
            "properties": {
                "elastic_credential": {
                    "keys": ["host", "port", "user", "password", "scheme"],
                    "properties": {
                        "host": {
                            "description": "",
                            "form": true,
                            "title": "Host",
                            "type": "string",
                            "value": ""
                        },
                        "port": {
                            "description": "",
                            "form": true,
                            "title": "Port",
                            "type": "string",
                            "value": ""
                        },
                        "user": {
                            "description": "",
                            "form": true,
                            "title": "User",
                            "type": "string",
                            "value": ""
                        },
                        "password": {
                            "description": "",
                            "form": true,
                            "hidden": true,
                            "title": "Password",
                            "type": "string",
                            "value": ""
                        },
                        "scheme": {
                            "description": "",
                            "form": true,
                            "title": "Scheme",
                            "type": "string",
                            "value": ""
                        },
                    },
                    "type": "object"
                }
            }
        }
    },
    {
        name: "loki",
        displayName: "Loki",
        config: {
            "keys": ["loki_credential"],
            "type": "object",
            "properties": {
                "loki_credential": {
                    "keys": ["host"],
                    "properties": {
                        "host": {
                            "description": "",
                            "form": true,
                            // "hidden": true,
                            "title": "Host",
                            "type": "string",
                            "value": ""
                        }
                    },
                    "type": "object"
                }
            }
        }
    },
    {
        name: "kafka",
        displayName: "Kafka",
        config: {
            "keys": ["kafka_credential"],
            "type": "object",
            "properties": {
                "kafka_credential": {
                    "keys": ["host", "default_topic", "format_type"],
                    "properties": {
                        "host": {
                            "description": "",
                            "form": true,
                            "title": "Host",
                            "type": "string",
                            "value": ""
                        },
                        "default_topic": {
                            "description": "",
                            "form": true,
                            "title": "Default Topic",
                            "type": "string",
                            "value": ""
                        },
                        "format_type": {
                            "description": "",
                            "form": true,
                            "title": "Format Type",
                            "type": "string",
                            "value": ""
                        }
                    },
                    "type": "object"
                }
            }
        }
    },
    {
        name: "gcp",
        displayName: "GCP",
        config: {
            "keys": ["gcp_credential"],
            "type": "object",
            "properties": {
                "gcp_credential": {
                    "keys": ["project", "bucket", "key_file"],
                    "properties": {
                        "project": {
                            "description": "",
                            "form": true,
                            "title": "Project ID",
                            "type": "string",
                            "value": ""
                        },
                        "bucket": {
                            "description": "",
                            "form": true,
                            // "hidden": true,
                            "title": "Bucket",
                            "type": "string",
                            "value": ""
                        },
                        "key_file": {
                            "description": "Select credential file",
                            "form": true,
                            "required": true,
                            "title": "Service account file",
                            "type": "file",
                            "value": ""
                        }
                    },
                    "type": "object"
                }
            }
        }
    }
    ],
    EnvSettingsTabs: {
        domain: 'domain',
        permissions: 'permissions',
        resources: 'resources',
        storage: 'storage',
        logger: "logger",
        variables: "variables",
        scheduler: "scheduler",
        startup: "startup",
        scripts: "scripts",
        externalsecret: "externalsecret",
        snapshot: "snapshot",
        restore: "restore",
        settings: "settings",
        cd: "cd",
        ci: "ci",
        ciVars:"ciVars",
        cistats:"cistats",
        alertdashboard:"dashboard",
        alertrules:"rules",
        alertnoti:"notifications",
        alerttemplates:"templates",
        alerthistory:"history",
    }
}