const initialState = {
    isValidatingPermission: false,
    validationData: null,
    isValidatingDnsPermission: false,
    validationDnsData: null,
    clusterList: null,
    fetchingClusterList: false,
    clusterDetails: null,
    fetchingClusterDetails: false,
    deletingCluster: false,
    destroyingCluster: false,
    applyingTerraform: false,
    newClusterData: null,
    gcpFilePath: "",
    exportingCluster: false,
    exportUrl: "",
    cancellingWorkflow: false,
    isUpdatingCluster: false,
    isCreatingCluster: false,
    clusterWorkflows: null,
    clusterWorkflowLog: [],
    clusterLogs: [],
    clusterWorkflowsLoaded: false,
    fetchingClusterWorkflows: false,
    providerConfig: null,
    fetchingClusterPackage: false,
    clusterPackageConfig: null,
    fetchingProviderConfig: false,
    appliedClusters: [],
    importingCluster: false, 
    installingPackage: false,
    uninstallingPackage: false,
    clusterPackageStatus: null,
    updatingRepoDetails: false,
    updatingDNS: false,
    redirectToCluster: false,
    clusterEnvDetails: null,
    fetchingClusterEnvDetails: false,
    settingClusterStorage: false,
    clusterInsights: null,
    fetchingClusterInsights: false,
    updatingClusterLabelsAndColor: false,
    providerPermissions: null,
    creatingVcluster:false,
    vClusterCreated:false,
    createVclusterError:null
};

export default initialState;
